<template>
  <div ref="loader" class="loader">
    <b-spinner type="grow" label="Loading..."></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'LoaderComponent',
}
</script>

<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.96);
}

.loader .spinner-grow {
  color: #41358e;
  --bs-spinner-width: 20vw;
  --bs-spinner-height: 20vw;
}

@media (max-width: 991.98px) {
  .loader .spinner-grow {
    --bs-spinner-width: 40vw;
    --bs-spinner-height: 40vw;
  }
}
</style>
