<template>
  <div>
    <Navbar />
    <div class="section">
      <div class="row">
        <div class="col-12 col-md-6">
          <h2>Selamat Datang di Web-Based Assessment</h2>
          <p class="mt-3">
            Media untuk melakukan penilaian kinerja keterampilan proses sains
            pada mata pelajaran fisika dalam bentuk two-tier test.
          </p>
        </div>
        <div class="col-12 col-md-6">
          <img src="@/assets/landing.png" draggable="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Navbar from '@/components/Navbar.vue'
import { toast } from 'vue3-toastify'

export default {
  name: 'BerandaPage',
  components: {
    Navbar,
  },
  mounted() {
    const token = localStorage.getItem('token')
    if (token) {
      this.getUser()
    }
  },
  methods: {
    async getUser() {
      await api
        .get('user')
        .then((response) => {
          if (response.data.data.nik) {
            this.$router.push({ name: 'GuruDashboard' })
          }

          if (response.data.data.nisn) {
            this.$router.push({ name: 'SiswaDashboard' })
          }
        })
        .catch((error) => {
          const tokenInvalid = error.response.status === 401

          if (tokenInvalid) {
            toast.error(error.response.data.message)
            localStorage.clear()
          }
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section {
  margin-top: 25vh;
}

h2 {
  color: #41358e;
}

img {
  width: 100%;
}

@media (max-width: 991.98px) {
  .section {
    margin-top: 10vh;
  }
}
</style>
