<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="row">
      <div class="col-12 align-self-center">
        <h2 class="mb-0 fw-bold">Ubah Dataset</h2>
      </div>
    </div>

    <hr class="mb-5" />

    <div class="row">
      <div class="col-12 col-md-6">
        <b-form-group label="Nama">
          <b-form-input
            v-model="form.nama"
            type="text"
            placeholder="Masukkan nama dataset"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group label="Deskripsi">
          <b-form-input
            v-model="form.deskripsi"
            type="text"
            placeholder="Masukkan deskripsi"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group label="Sekolah">
          <b-form-input
            v-model="form.sekolah.nama"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 mt-5 text-end">
        <b-button class="btn-purple me-2" @click="handleBack">
          Kembali
        </b-button>
        <b-button class="btn-edit" @click="handleUbah">
          <i class="bi-pencil"></i>
          Ubah Dataset
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import api from '@/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'UbahDatasetPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      form: {
        nama: '',
        deskripsi: '',
        sekolah: '',
      },
    }
  },
  mounted() {
    this.getDataset()
  },
  methods: {
    async getDataset() {
      await api
        .get(`dataset?dataset_id=${this.$route.params.id}`)
        .then((response) => {
          this.form = {
            nama: response.data.nama,
            deskripsi: response.data.deskripsi,
            sekolah: response.data.sekolah,
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async handleUbah() {
      this.isLoading = true
      const { sekolah, ...postData } = this.form

      await api
        .put(`dataset/${this.$route.params.id}`, postData)
        .then((response) => {
          toast.success(response.data.message)
          setTimeout(() => {
            this.$router.push({ name: 'Dataset' })
          }, 2000)
        })
        .catch((error) => {
          toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleBack() {
      this.$router.push({ name: 'Dataset' })
    },
  },
}
</script>

<style scoped></style>
