<template>
  <div>
    <Topbar :key="$route.fullPath" />

    <div class="d-block">
      <Sidebar />

      <main :class="{ main: true, open: isSidebarOpen }">
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>

        <div class="flex-grow-1" />
        <Footer />
      </main>
    </div>
  </div>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    Topbar,
    Sidebar,
    Footer,
  },
  computed: {
    ...mapGetters(['getSidebarStatus']),
    isSidebarOpen() {
      return this.getSidebarStatus
    },
  },
  mounted() {
    const token = localStorage.getItem('token')
    if (!token) {
      this.$router.push({ name: 'Beranda' })
    }
  }
}
</script>

<style scoped>
.main {
  background: #fff;
  min-height: calc(100vh - 80px);
  padding: 2rem 5rem 0 5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: width 0.24s ease-in-out;
  width: 100%;
  float: right;
  margin-top: 80px;
}

.main.open {
  width: calc(100% - 120px);
}

/* fhd */
@media (min-width: 1440px) {
  .main {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }
}

/* mobile */
@media (max-width: 991.98px) {
  .main {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .section {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
</style>
