<template>
  <div class="footer col-12 flex-shrink-0 bg-weak">
    <div class="row">
      <div class="col-12">
        <p class="fc-light">
          <strong>Web-Based Assessment</strong>
        </p>
      </div>
    </div>
    <div
      class="border-top pt-3 d-flex flex-column flex-sm-row align-items-center"
    >
      <div class="d-flex align-items-center">
        <img
          src="@/assets/logo.png"
          alt=""
          width="60"
          height="60"
          class="logo"
        />
        <div>
          <p class="m-0 fc-light">© 2024</p>
          <p class="m-0 fc-light">All rights reserved</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
.footer {
  background: #41358e;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 2rem;
  padding: 1.25rem;
  color: #fff;
}

.logo {
  height: auto;
  margin-right: 2rem;
  width: 3rem;
}
</style>
