<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="row">
      <div class="col-12 col-md-6 mb-3 align-self-center">
        <h2 class="mb-0 fw-bold">Soal</h2>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group label="Pilih dataset">
          <b-form-select
            v-model="dataset_id"
            :options="datasetOptions"
            @change="getSoal"
            autofocus
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="dataset_id" class="lompat" label="Pindah ke soal-">
          <b-form-select v-model="lompat" @change="lompatKe()" autofocus>
            <option v-for="n in 30" :key="n" :value="n">{{ n }}</option>
          </b-form-select>
        </b-form-group>
      </div>
    </div>

    <hr class="mb-5" />

    <div
      v-if="soalTidakLengkap.length"
      class="alert alert-warning mb-5"
      role="alert"
    >
      Terdapat soal yang tidak lengkap:
      <b
        class="soalTidakLengkap"
        @click=";(lompat = item), lompatKe()"
        v-for="item in soalTidakLengkap"
        :key="item.id"
      >
        <b-badge pill variant="danger" class="me-1">{{ item }}</b-badge>
      </b>
    </div>

    <div v-if="soal.length">
      <div
        :ref="`soal${index + 1}`"
        v-for="(item, index) in soal"
        :key="item.id"
        class="mb-5"
      >
        <div class="row">
          <div class="col-12">
            <h4 class="fw-bold">Soal {{ index + 1 }}</h4>
          </div>

          <div class="col-6">
            <b-form-group label="Tier Satu Pertanyaan">
              <b-form-textarea
                v-model="item.tier_satu_pertanyaan"
                placeholder="Masukkan Pertanyaan"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <div class="row">
              <div class="col-6">
                <b-form-group label="Tier Satu Jawaban">
                  <b-form-select
                    v-model="item.tier_satu_jawaban"
                    :options="jawabanOptions"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Opsi B">
                  <b-form-input
                    v-model="item.tier_satu_b"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Opsi D">
                  <b-form-input
                    v-model="item.tier_satu_d"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Link Gambar">
                  <b-form-input
                    v-model="item.gambar"
                    type="text"
                    placeholder="https://domain.com/gambar.jpg"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-6">
                <b-form-group label="Opsi A">
                  <b-form-input
                    v-model="item.tier_satu_a"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Opsi C">
                  <b-form-input
                    v-model="item.tier_satu_c"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Opsi E">
                  <b-form-input
                    v-model="item.tier_satu_e"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <img :src="item.gambar" width="100%" />
              </div>
            </div>
          </div>

          <div class="col-6">
            <b-form-group label="Tier Dua Pertanyaan">
              <b-form-textarea
                v-model="item.tier_dua_pertanyaan"
                placeholder="Masukkan Pertanyaan"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <div class="row">
              <div class="col-6">
                <b-form-group label="Tier Dua Jawaban">
                  <b-form-select
                    v-model="item.tier_dua_jawaban"
                    :options="jawabanOptions"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Opsi B">
                  <b-form-input
                    v-model="item.tier_dua_b"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Opsi D">
                  <b-form-input
                    v-model="item.tier_dua_d"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group label="Opsi A">
                  <b-form-input
                    v-model="item.tier_dua_a"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Opsi C">
                  <b-form-input
                    v-model="item.tier_dua_c"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Opsi E">
                  <b-form-input
                    v-model="item.tier_dua_e"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-danger" role="alert">
      Tidak ada data soal, silahkan pilih dataset terlebih dahulu pada menu di
      atas kanan.
    </div>

    <div
      v-if="soalTidakLengkap.length"
      class="alert alert-warning"
      role="alert"
    >
      Terdapat soal yang tidak lengkap:
      <b
        class="soalTidakLengkap"
        @click=";(lompat = item), lompatKe()"
        v-for="item in soalTidakLengkap"
        :key="item.id"
      >
        <b-badge pill variant="danger" class="me-1">{{ item }}</b-badge>
      </b>
    </div>

    <b-button v-if="dataset_id" class="btn-edit simpan" @click="handleSimpan">
      <i class="bi-pencil"></i>
      Ubah Soal
    </b-button>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import api from '@/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'SoalPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      dataset_id: null,
      datasetOptions: [],
      soal: [],
      soalFields: [
        { key: 'no', label: 'No' },
        { key: 'tier_satu_pertanyaan', label: 'Tier Satu Pertanyaan' },
        { key: 'tier_satu_a', label: 'A' },
        { key: 'tier_satu_b', label: 'B' },
        { key: 'tier_satu_c', label: 'C' },
        { key: 'tier_satu_d', label: 'D' },
        { key: 'tier_satu_e', label: 'E' },
        { key: 'gambar', label: 'Gambar' },
        { key: 'tier_satu_jawaban', label: 'Tier Satu Jawaban' },
        { key: 'tier_dua_pertanyaan', label: 'Tier Dua Pertanyaan' },
        { key: 'tier_dua_a', label: 'A' },
        { key: 'tier_dua_b', label: 'B' },
        { key: 'tier_dua_c', label: 'C' },
        { key: 'tier_dua_d', label: 'D' },
        { key: 'tier_dua_e', label: 'E' },
        { key: 'tier_dua_jawaban', label: 'Tier Dua Jawaban' },
        { key: 'aksi', label: 'Aksi' },
      ],
      jawabanOptions: ['a', 'b', 'c', 'd', 'e'],
      lompat: null,
      soalTidakLengkap: [],
    }
  },
  mounted() {
    this.getDataset()
  },
  beforeUnmount() {
    if (!localStorage.getItem('token')) {
      return
    }

    if (this.dataset_id) {
      this.handleSimpan()
    }
  },
  methods: {
    async getDataset() {
      await api
        .get('dataset')
        .then((response) => {
          const dataset = response.data.filter(item => item.status == 0);

          this.datasetOptions = dataset.map((item) => {
            return { value: item.id, text: item.nama }
          })
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    lompatKe() {
      this.$nextTick(() => {
        const targetElement = this.$refs[`soal${this.lompat}`][0]
        const offset =
          7 * parseFloat(getComputedStyle(document.documentElement).fontSize)

        const elementPosition =
          targetElement.getBoundingClientRect().top + window.scrollY
        const offsetPosition = elementPosition - offset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      })
    },
    async getSoal() {
      if (this.dataset_id) {
        this.handleSimpan()
      }

      this.isLoading = true

      await this.$nextTick(() => {
        api
          .get(`soal?dataset_id=${this.dataset_id}`)
          .then((response) => {
            this.soal = response.data.soal
            this.soalTidakLengkap = response.data.soal_tidak_lengkap
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    handleEdit(id) {
      this.$router.push(`/guru/ubah-soal/${id}`)
    },
    async handleSimpan() {
      this.isLoading = true

      await api
        .put('soal', {
          dataset_id: this.dataset_id,
          soal: this.soal,
        })
        .then((response) => {
          this.soalTidakLengkap = response.data.soal_tidak_lengkap
          toast.success(response.data.message)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped>
.lompat {
  border-radius: 1rem;
  padding: 1rem 2rem;
  background: #fff;
  position: fixed;
  left: 12.5rem;
  margin-bottom: 0 !important;
  bottom: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.soalTidakLengkap:hover {
  cursor: pointer;
}

.simpan {
  position: fixed;
  right: 5rem;
  bottom: 1rem;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
