<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="row">
      <div class="col-12 col-md-6 mb-3 mb-md-0 align-self-center">
        <h2 class="mb-0 fw-bold">Siswa</h2>
      </div>
      <div class="col-12 col-md-6 align-self-center text-center text-md-end">
        <b-button @click="handleTambah" class="btn-blue">
          <i class="bi-plus-circle me-2"></i>
          Tambah Siswa
        </b-button>
      </div>
    </div>

    <hr class="mb-5" />

    <b-table
      v-if="siswa.length"
      :items="siswa"
      :fields="siswaFields"
      striped
      responsive
    >
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(sekolah)="data">
        {{ data.item.sekolah.nama }}
      </template>
      <template #cell(aksi)="data">
        <b-button
          @click="handleEdit(data.item.id)"
          size="sm"
          class="btn-edit me-2 mb-2 mb-md-0"
        >
          <i class="bi-pencil"></i>
        </b-button>
        <b-button
          @click="handleDelete(data.item.id)"
          size="sm"
          class="btn-delete"
        >
          <i class="bi-trash"></i>
        </b-button>
      </template>
    </b-table>
    <div v-else class="alert alert-danger" role="alert">
      Tidak ada data siswa
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import api from '@/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'SiswaPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      siswa: [],
      siswaFields: [
        { key: 'no', label: 'No' },
        { key: 'nisn', label: 'NISN' },
        { key: 'nama', label: 'Nama' },
        { key: 'kelas', label: 'Kelas' },
        { key: 'sekolah', label: 'Sekolah' },
        { key: 'aksi', label: 'Aksi' },
      ],
    }
  },
  created() {
    this.getSiswa()
  },
  methods: {
    handleTambah() {
      this.$router.push({ name: 'TambahSiswa' })
    },
    async getSiswa() {
      this.isLoading = true
      
      await api
        .get(`siswa`)
        .then((response) => {
          this.siswa = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleEdit(id) {
      this.$router.push(`/guru/ubah-siswa/${id}`)
    },
    async handleDelete(id) {
      if (!confirm('Apakah anda yakin ingin menghapus data ini?')) {
        return
      }

      await api
        .delete(`siswa/${id}`)
        .then((response) => {
          toast.success(response.data.message)
          this.getSiswa()
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style scoped></style>
