<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="row">
      <div class="col-12 col-md-6 mb-3 mb-md-0 align-self-center">
        <h2 class="mb-0 fw-bold">Soal No {{ soalActive }}</h2>
      </div>

      <div class="col-12 h-100 col-md-6 align-self-center text-end">
        <div class="waktu">
          <span class="title fw-bold">Sisa Waktu</span>
          <span class="countdown fw-bold">{{ timeLeft }}</span>
        </div>
      </div>
    </div>

    <hr class="mb-5" />

    <div v-if="jawaban.length" class="row">
      <div class="col-8 soal">
        <img
          v-if="soal[soalActive - 1].gambar"
          :src="soal[soalActive - 1].gambar"
          class="mb-3"
          alt="Soal"
        />

        <div class="row">
          <div class="col-12 mb-5">
            <p class="fw-bold">
              {{ soal[soalActive - 1].tier_satu_pertanyaan }}
            </p>
            <hr />

            <b-form-radio-group
              v-model="jawaban[soalActive - 1].tier_satu_jawaban"
              :options="opsiTierSatu[soalActive - 1]"
              stacked
            ></b-form-radio-group>
          </div>

          <div class="col-12">
            <p class="fw-bold">
              {{ soal[soalActive - 1].tier_satu_pertanyaan }}
            </p>
            <hr />

            <b-form-radio-group
              v-model="jawaban[soalActive - 1].tier_dua_jawaban"
              :options="opsiTierDua[soalActive - 1]"
              stacked
            ></b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="col-4 index">
        <div class="card">
          <span class="fw-bold text-center">Nomor Soal</span>

          <hr />

          <div class="row">
            <div
              v-for="(item, index) in soal"
              :key="item.id"
              class="col-2 mb-2 align-content-center"
            >
              <b-badge
                pill
                :variant="
                  jawaban[index]?.tier_satu_jawaban &&
                  jawaban[index]?.tier_dua_jawaban
                    ? 'success'
                    : 'secondary'
                "
                :class="`me-1 w-100 h-100 align-content-center ${
                  soalActive === index + 1 ? 'fw-bold' : ''
                }`"
                @click="handleChangeQuestion(index, 'badge')"
              >
                {{ index + 1 }}
              </b-badge>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-4 text-center">
              <p class="bg-success text-white mb-1">Hijau</p>
              <p class="bg-secondary text-white mb-1">Abu-abu</p>
            </div>
            <div class="col-auto">
              <p class="mb-1">= Sudah dijawab</p>
              <p class="mb-1">= Belum dijawab</p>
            </div>
          </div>
        </div>

        <div class="mt-3 d-flex justify-content-between">
          <b-button
            @click="handleChangeQuestion(null, 'prev')"
            size="sm"
            class="btn-edit text-black"
          >
            <i class="bi-arrow-left"></i>
            Soal Sebelumnya
          </b-button>

          <b-button
            @click="handleChangeQuestion(null, 'next')"
            size="sm"
            class="btn-edit text-black"
          >
            Soal Selanjutnya
            <i class="bi-arrow-right"></i>
          </b-button>
        </div>

        <b-button
          @click="handleSubmitJawaban('selesai')"
          class="btn-blue mt-3 w-100"
        >
          <i class="bi-save"></i>
          Selesai
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import api from '@/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'JawabSoalPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      soal: [],
      soalActive: 1,
      jawaban: [],
      opsiTierSatu: [],
      opsiTierDua: [],
      timeStart: '2024-10-07 00:26:00',
      timeLeft: '',
      timeInterval: null,
      jawabanId: null,
    }
  },
  mounted() {
    this.getSoal()
  },
  created() {
    this.timeInterval = setInterval(() => {
      this.countdown()
    }, 1000)
  },
  beforeUnmount() {
    clearInterval(this.timeInterval)
  },
  methods: {
    async getSoal() {
      this.isLoading = true

      await api
        .get(`/jawab/${this.$route.params.id}`)
        .then((response) => {
          this.soal = response.data

          this.opsiTierSatu = this.soal.map((item) => {
            return [
              { text: item.tier_satu_a, value: 'a' },
              { text: item.tier_satu_b, value: 'b' },
              { text: item.tier_satu_c, value: 'c' },
              { text: item.tier_satu_d, value: 'd' },
              { text: item.tier_satu_e, value: 'e' },
            ]
          })

          this.opsiTierDua = this.soal.map((item) => {
            return [
              { text: item.tier_dua_a, value: 'a' },
              { text: item.tier_dua_b, value: 'b' },
              { text: item.tier_dua_c, value: 'c' },
              { text: item.tier_dua_d, value: 'd' },
              { text: item.tier_dua_e, value: 'e' },
            ]
          })
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.getDataJawab()
        })
    },
    async getDataJawab() {
      await api
        .get(`/datajawab/${this.$route.params.id}`)
        .then((response) => {
          const { start_at, end_at, jawaban, id } = response.data
          this.timeStart = start_at
          this.jawabanId = id

          if (end_at) {
            this.$router.push({ name: 'Paket' })
          }

          if (jawaban.length) {
            return (this.jawaban = jawaban)
          }

          this.jawaban = this.soal.map((item) => {
            return {
              tier_satu_jawaban: '',
              tier_dua_jawaban: '',
            }
          })
        })
        .catch(() => {
          this.$router.push({ name: 'Paket' })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async handleSaveJawaban() {
      await api
        .put(`/jawab/${this.jawabanId}`, {
          jawaban: this.jawaban,
        })
        .catch((error) => {
          console.error(error)
          toast.error('Gagal menyimpan jawaban')
        })
    },
    async handleSubmitJawaban(from) {
      if (from === 'selesai') {
        if (!confirm('Apakah anda yakin ingin menyelesaikan ujian?')) {
          return
        }
      }

      await this.handleSaveJawaban()
      await api
        .put(`/selesai/${this.jawabanId}`)
        .then(() => {
          toast.success('Jawaban berhasil disimpan')
        })
        .catch((error) => {
          console.error(error)
          toast.error('Gagal menyimpan jawaban')
        })
        .finally(() => {
          setTimeout(() => {
            this.$router.push({ name: 'Paket' })
          }, 2000)
        })
    },
    countdown() {
      const startAtDate = new Date(this.timeStart.replace(' ', 'T'))
      const timeLimitTimestamp = startAtDate.getTime() + 3600 * 1000
      const currentTime = new Date().getTime()
      console.log(new Date())
      const timeLeft = Math.floor((timeLimitTimestamp - currentTime) / 1000)

      if (timeLeft <= 0) {
        this.timeLeft = '00:00'
        toast.error('Waktu habis')
        this.handleSaveJawaban()
        clearInterval(this.timeInterval)
        setTimeout(() => {
          this.handleSubmitJawaban('countdown')
        }, 2000)
        return
      }
      const minutes = Math.floor(timeLeft / 60)
      const seconds = timeLeft % 60
      this.timeLeft = `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    },
    handleChangeQuestion(index, from) {
      this.handleSaveJawaban()
      window.scrollTo({ top: 0, behavior: 'smooth' })

      if (from === 'prev') {
        return this.soalActive !== 1
          ? (this.soalActive = this.soalActive - 1)
          : null
      }

      if (from === 'next') {
        return this.soalActive !== this.soal.length
          ? (this.soalActive = this.soalActive + 1)
          : null
      }

      this.soalActive = index + 1
    },
  },
}
</script>

<style scoped>
.waktu {
  height: 2rem;
  color: #fff;
}

.waktu .title {
  background: #6c757d;
  padding: 0.5rem;
}

.waktu .countdown {
  background: #41358e;
  padding: 0.5rem;
}

.soal img {
  max-height: 20rem;
}

.index {
  width: 27%;
  position: fixed;
  right: 5rem;
  bottom: 9.5rem;
}

.index .card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 1rem;
}

.index .badge:hover {
  cursor: pointer;
}

/* fhd */
@media (min-width: 1440px) {
  .index {
    width: 20%;
    position: fixed;
    right: 20rem;
    bottom: unset;
  }
}
</style>
