<template>
  <router-view />
</template>

<script>
import { watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import 'vue3-toastify/dist/index.css'

export default {
  setup() {
    const route = useRoute()

    watchEffect(() => {
      document.title = route.meta.title || 'Penilaian KPS'
    })
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #323232;
}

a:hover,
a:active {
  color: #41358e;
}

.section {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
  margin-left: unset !important;
  margin-right: unset !important;
}

.btn {
  border-radius: 10rem !important;
  padding: 0.3rem 1rem !important;
}

.btn:focus, .btn:active {
  color: #fff !important;
}

.btn-purple {
  background: #41358e !important;
  border: 1px solid #41358e !important;
  color: #fff !important;
}

.btn-purple:hover,
.btn-purple:active {
  background: #41358e !important;
  border-color: #41358e !important;
  box-shadow: 0 8px 25px -8px #41358e !important;
}

.btn-blue {
  background: #3b82f6 !important;
  border: 1px solid #3b82f6 !important;
  color: #fff !important;
}

.btn-blue:hover,
.btn-blue:active {
  background: #3b82f6 !important;
  border-color: #3b82f6 !important;
  box-shadow: 0 8px 25px -8px #3b82f6 !important;
}

.btn-edit {
  background: #fef000 !important;
  border: 1px solid #fef000 !important;
  color: #323232 !important;
}

.btn-edit:hover,
.btn-edit:active,
.btn-edit:focus {
  color: #323232 !important;
  background: #fef000 !important;
  border-color: #fef000 !important;
  box-shadow: 0 8px 25px -8px #fef000 !important;
}

.btn-delete {
  background: #c90000 !important;
  border: 1px solid #c90000 !important;
  color: #fff !important;
}

.btn-delete:hover,
.btn-delete:active {
  background: #c90000 !important;
  border-color: #c90000 !important;
  box-shadow: 0 8px 25px -8px #c90000 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #41358e !important;
  color: #fff !important;
}

.topbar .btn-check:checked + .btn,
.topbar .btn.active,
.topbar .btn.show,
.topbar .btn:first-child:active {
  color: #41358e !important;
}

.dropdown-toggle {
  display: flex !important;
  align-items: center !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: rgba(65, 53, 142, 0.1);
}

/* fhd */
@media (min-width: 1440px) {
  .section {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }
}

/* mobile */
@media (max-width: 991.98px) {
  .section {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
</style>
