<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="row">
      <div class="col-12 align-self-center">
        <h2 class="mb-0 fw-bold">Ubah Siswa</h2>
      </div>
    </div>

    <hr class="mb-5" />

    <div class="row">
      <div class="col-12 col-md-6">
        <b-form-group label="NISN">
          <b-form-input
            v-model="form.nisn"
            type="number"
            placeholder="Masukkan NISN siswa"
            autofocus
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group label="Nama">
          <b-form-input
            v-model="form.nama"
            type="text"
            placeholder="Masukkan nama siswa"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group label="Kelas">
          <b-form-input
            v-model="form.kelas"
            type="text"
            placeholder="Masukkan kelas siswa"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group label="Sekolah">
          <b-form-input
            v-model="form.sekolah.nama"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group
          label="Kata Sandi"
          description="Kosongkan jika tidak merubah kata sandi"
        >
          <b-form-input v-model="form.password" type="password"></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 mt-5 text-end">
        <b-button class="btn-purple me-2" @click="handleBack">
          Kembali
        </b-button>
        <b-button class="btn-edit" @click="handleUbah">
          <i class="bi-pencil"></i>
          Ubah Siswa
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import api from '@/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'UbahSiswaPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      form: {
        nisn: '',
        nama: '',
        kelas: '',
        sekolah: '',
        password: '',
      },
    }
  },
  mounted() {
    this.getSiswa()
  },
  methods: {
    async getSiswa() {
      await api
        .get(`siswa?siswa_id=${this.$route.params.id}`)
        .then((response) => {
          this.form = {
            nisn: response.data.nisn,
            nama: response.data.nama,
            kelas: response.data.kelas,
            sekolah: response.data.sekolah,
            password: '',
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async handleUbah() {
      this.isLoading = true
      const { sekolah, ...postData } = this.form

      await api
        .put(`siswa/${this.$route.params.id}`, postData)
        .then((response) => {
          toast.success(response.data.message)
          setTimeout(() => {
            this.$router.push({ name: 'Siswa' })
          }, 2000)
        })
        .catch((error) => {
          toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleBack() {
      this.$router.push({ name: 'Siswa' })
    },
  },
}
</script>

<style scoped></style>
