<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="row">
      <div class="col-12 col-md-6 mb-3 mb-md-0 align-self-center">
        <h2 class="mb-0 fw-bold">Dataset</h2>
      </div>
      <div class="col-12 col-md-6 align-self-center text-center text-md-end">
        <b-button @click="handleTambah" class="btn-blue">
          <i class="bi-plus-circle me-2"></i>
          Tambah Dataset
        </b-button>
      </div>
    </div>

    <hr class="mb-5" />

    <b-table
      v-if="dataset.length"
      :items="dataset"
      :fields="datasetFields"
      striped
      responsive
    >
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(soal_tidak_lengkap)="data">
        <span v-if="!data.item.soal_tidak_lengkap.length">-</span>
        <span v-for="(soal, index) in data.item.soal_tidak_lengkap" :key="index">
          <b-badge pill variant="danger" class="me-1">{{ soal }}</b-badge>
        </span>
        
      </template>
      <template #cell(aksi)="data">
        <b-button
          v-if="!data.item.soal_tidak_lengkap.length && data.item.status == false"
          @click="handleStart(data.item.id)"
          size="sm"
          class="btn-purple me-2 mb-2"
        >
          <i class="bi-power"></i>
        </b-button>
        <b-button
          v-if="data.item.status == false"
          @click="handleEdit(data.item.id)"
          size="sm"
          class="btn-edit me-2 mb-2"
        >
          <i class="bi-pencil"></i>
        </b-button>
        <b-button
          v-if="data.item.status == false"
          @click="handleDelete(data.item.id)"
          size="sm"
          class="btn-delete mb-2"
        >
          <i class="bi-trash"></i>
        </b-button>
      </template>
    </b-table>
    <div v-else class="alert alert-danger" role="alert">
      Tidak ada data dataset
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import api from '@/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'DatasetPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      dataset: [],
      datasetFields: [
        { key: 'no', label: 'No' },
        { key: 'nama', label: 'Nama' },
        { key: 'deskripsi', label: 'Deskripsi' },
        { key: 'soal_tidak_lengkap', label: 'Soal Tidak Lengkap' },
        { key: 'aksi', label: 'Aksi' },
      ],
    }
  },
  mounted() {
    this.getDataset()
  },
  methods: {
    handleTambah() {
      this.$router.push({ name: 'TambahDataset' })
    },
    async getDataset() {
      this.isLoading = true
      
      await api
        .get('dataset')
        .then((response) => {
          this.dataset = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleEdit(id) {
      this.$router.push(`/guru/ubah-dataset/${id}`)
    },
    async handleStart(id) {
      if (!confirm('Apakah anda yakin ingin memulai dataset?')) {
        return
      }

      await api
        .get(`dataset-start/${id}`)
        .then((response) => {
          toast.success(response.data.message)
          this.getDataset()
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async handleDelete(id) {
      if (!confirm('Apakah anda yakin ingin menghapus data ini?')) {
        return
      }

      await api
        .delete(`dataset/${id}`)
        .then((response) => {
          toast.success(response.data.message)
          this.getDataset()
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style scoped></style>
