<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="row">
      <div class="col-12 col-md-6 mb-3 mb-md-0 align-self-center">
        <h2 class="mb-0 fw-bold">Kerjakan Paket Soal</h2>
      </div>
    </div>

    <hr class="mb-5" />
    <b-table
      v-if="dataset.length"
      :items="dataset"
      :fields="datasetFields"
      striped
      responsive
    >
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(jawaban)="data">
        {{ data.item.jawaban.length ? data.item.jawaban[0].start_at : '-' }}
      </template>
      <template #cell(aksi)="data">
        <b-button
          v-if="data.item.jawaban.length === 0"
          @click="handleStart(data.item.id)"
          size="sm"
          class="btn-purple me-2 mb-2"
        >
          <i class="bi-power"></i>
        </b-button>
        <b-button
          v-else-if="!isMoreThanHour(data.item.jawaban[0].start_at)"
          @click="goToJawabSoal(data.item.id)"
          size="sm"
          class="btn-blue me-2 mb-2"
        >
          <i class="bi-play"></i>
        </b-button>
      </template>
    </b-table>
    <div v-else class="alert alert-danger" role="alert">
      Tidak ada paket soal yang tersedia
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import api from '@/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'PaketPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: true,
      dataset: [],
      datasetFields: [
        { key: 'no', label: 'No' },
        { key: 'nama', label: 'Nama' },
        { key: 'deskripsi', label: 'Deskripsi' },
        { key: 'jawaban', label: 'Waktu Mulai' },
        { key: 'aksi', label: 'Aksi' },
      ],
    }
  },
  mounted() {
    this.getTersedia()
  },
  methods: {
    isMoreThanHour(startAt) {
      const startAtDate = new Date(startAt.replace(' ', 'T'))
      const startAtTimestamp = Math.floor(startAtDate.getTime() / 1000)
      const currentTimestamp = Math.floor(Date.now() / 1000)
      return startAtTimestamp < currentTimestamp - 3600
    },
    async getTersedia() {
      this.isLoading = true

      await api
        .get('dataset-siswa')
        .then((response) => {
          this.dataset = response.data.filter((item) => {
            if (item.jawaban.length === 0) {
              return true
            }
            
            if (item.jawaban[0].end_at) {
              return false
            }

            return !this.isMoreThanHour(item.jawaban[0].start_at)
          })
          
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async handleStart(id) {
      if (!confirm('Apakah anda yakin ingin mulai menjawab paket soal?')) {
        return
      }

      await api
        .put(`dataset-siswa/start/${id}`)
        .then(() => {
          toast.success('Paket soal berhasil dimulai')
          this.$router.push(`/siswa/jawab-soal/${id}`)
        })
        .catch((error) => {
          console.error(error)
          toast.error('Gagal memulai paket soal')
        })
    },
    goToJawabSoal(id) {
      this.$router.push(`/siswa/jawab-soal/${id}`)
    },
  },
}
</script>

<style scoped></style>
