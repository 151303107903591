<template>
  <div>
    <b-navbar class="topbar bg-weak" toggleable="lg">
      <b-navbar-brand class="ml-3 mr-5" href="#">
        <img src="@/assets/logo.png" />
      </b-navbar-brand>
      <i @click="handleSidebar" class="topbar-toggle fc-light bi-list"></i>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ms-auto">
          <b-nav-item-dropdown :right="true">
            <template #button-content>
              <em class="fc-light">
                {{ `${user.nama} - ${user.nik || user.nisn}` }}
              </em>
            </template>
            <b-dropdown-item @click="handleLogout">Keluar</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters, mapMutations } from 'vuex'
import { toast } from 'vue3-toastify'

export default {
  name: 'Topbar',
  data() {
    return {
      refreshTokenIntervalId: null,
    }
  },
  computed: {
    ...mapGetters(['getUserData', 'getSidebarStatus']),
    user() {
      return this.getUserData
    },
    isSidebarOpen() {
      return this.getSidebarStatus
    },
    token() {
      return localStorage.getItem('token')
    },
  },
  created() {
    this.fetchUserToken()
    this.refreshTokenIntervalId = setInterval(() => {
      this.fetchUserToken()
    }, 60000)
  },
  beforeUnmount() {
    this.stopTokenFetch()
  },
  methods: {
    ...mapMutations(['toggleSidebar', 'setUserData']),
    async fetchUserToken() {
      await api
        .get('user')
        .then((response) => {
          this.setUserData(response.data.data)

          const parentRoute = this.$route.matched[0].name
          const isOnGuruRoute = parentRoute === 'GuruDashboard'
          const isOnSiswaRoute = parentRoute === 'SiswaDashboard'
          const isGuruAccount = response.data.data.nik
          const isSiswaAccount = response.data.data.nisn
          const isReturnToSiswaDashboard = isOnGuruRoute && isSiswaAccount
          const isReturnToGuruDashboard = isOnSiswaRoute && isGuruAccount

          if (isReturnToSiswaDashboard) {
            return this.$router.push({ name: 'SiswaDashboard' })
          }

          if (isReturnToGuruDashboard) {
            return this.$router.push({ name: 'GuruDashboard' })
          }
        })
        .catch((error) => {
          const tokenInvalid = error.response.status === 401

          if (tokenInvalid) {
            toast.error(error.response.data.message)
            this.stopTokenFetch()
            setTimeout(() => {
              localStorage.clear()
              this.$router.push({ name: 'Beranda' })
            }, 2000)
          }
        })
    },
    stopTokenFetch() {
      clearInterval(this.refreshTokenIntervalId)
      this.refreshTokenIntervalId = null
    },
    async handleLogout() {
      await api
        .get('logout')
        .then((response) => {
          toast.success(response.data.message)
          this.stopTokenFetch()

          setTimeout(() => {
            localStorage.clear()
            this.$router.push({ name: 'Beranda' })
          }, 2000)
        })
        .catch((error) => {
          const tokenInvalid = error.response.status === 401

          if (tokenInvalid) {
            toast.error(error.response.data.message)
            this.stopTokenFetch()
            setTimeout(() => {
              localStorage.clear()
              this.$router.push({ name: 'Beranda' })
            }, 2000)
          }
        })
    },
    handleSidebar() {
      this.toggleSidebar()
    },
  },
}
</script>

<style scoped>
.topbar {
  background: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  height: 80px;
  z-index: 3;
  position: fixed;
  width: 100%;
}

.topbar img {
  max-width: 3rem;
}

.topbar-toggle {
  font-size: 30px;
  color: #111827;
}

.topbar-toggle:hover {
  cursor: pointer;
}

/* mobile */
@media (max-width: 991.98px) {
  .topbar img {
    max-width: 2.5rem;
  }
}
</style>
