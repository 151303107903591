import { createRouter, createWebHistory } from 'vue-router'

// Landing
import Beranda from '@/pages/landing/Beranda.vue'
import Masuk from '@/pages/landing/Masuk.vue'
import Template from '@/pages/Template.vue'

// Guru
import Siswa from '@/pages/guru/Siswa.vue'
import TambahSiswa from '@/pages/guru/TambahSiswa.vue'
import UbahSiswa from '@/pages/guru/UbahSiswa.vue'
import Dataset from '@/pages/guru/Dataset.vue'
import TambahDataset from '@/pages/guru/TambahDataset.vue'
import UbahDataset from '@/pages/guru/UbahDataset.vue'
import Soal from '@/pages/guru/Soal.vue'

//Siswa
import Paket from '@/pages/siswa/Paket.vue'
import JawabSoal from '@/pages/siswa/JawabSoal.vue'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: Beranda,
    meta: { title: 'Beranda | Penilaian KPS' },
  },
  {
    path: '/masuk',
    name: 'Masuk',
    component: Masuk,
    meta: { title: 'Masuk | Penilaian KPS' },
  },
  {
    path: '/guru',
    name: 'GuruDashboard',
    component: Template,
    meta: { title: 'Guru | Penilaian KPS' },
    children: [
      { path: 'siswa', name: 'Siswa', component: Siswa },
      { path: 'tambah-siswa', name: 'TambahSiswa', component: TambahSiswa },
      { path: 'ubah-siswa/:id', name: 'UbahSiswa', component: UbahSiswa },
      { path: 'dataset', name: 'Dataset', component: Dataset },
      {
        path: 'tambah-dataset',
        name: 'TambahDataset',
        component: TambahDataset,
      },
      { path: 'ubah-dataset/:id', name: 'UbahDataset', component: UbahDataset },
      { path: 'soal', name: 'Soal', component: Soal },
    ],
    redirect: { name: 'Siswa' },
  },
  {
    path: '/siswa',
    name: 'SiswaDashboard',
    component: Template,
    meta: { title: 'Siswa | Penilaian KPS' },
    children: [
      { path: 'paket', name: 'Paket', component: Paket },
      { path: 'jawab-soal/:id', name: 'JawabSoal', component: JawabSoal },
    ],
    redirect: { name: 'Paket' },
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
})

export default router
