import { createStore } from 'vuex'

const store = createStore({
  state: {
    user: {},
    sidebar: {
      isOpen: true,
    },
  },
  mutations: {
    setUserData(state, userData) {
      state.user = userData
    },
    toggleSidebar(state) {
      state.sidebar.isOpen = !state.sidebar.isOpen
    },
  },
  actions: {
    updateUserData({ commit }, userData) {
      commit('setUserData', userData)
    },
  },
  getters: {
    getUserData: (state) => state.user,
    getSidebarStatus: (state) => state.sidebar.isOpen,
  },
})

export default store
