<template>
  <div>
    <Loader v-if="isLoading" />
    <Navbar />
    <div class="outer">
      <div class="masuk d-flex">
        <div class="left">
          <p class="mb-0">Selamat Datang</p>
          <h4 class="mb-0 fw-bold">di Web-Based Assessment</h4>
          <p style="color: #808080;">
            Web-Based Assessment - Media Penilaian Kinerja Keterampilan Proses
            Sains Mata Pelajaran Fisika.
          </p>
          <b-form>
            <b-form-group label="Tipe Akun">
              <b-form-select
                v-model="form.type"
                :options="typeOptions"
              ></b-form-select>
            </b-form-group>

            <b-form-group label="NIK (Guru) atau NISN (Siswa)">
              <b-form-input
                v-model="form.identity"
                type="number"
                placeholder="Masukkan NIK (Guru) atau NISN (Siswa)"
                autofocus
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Kata Sandi"
              description="Jangan pernah berbagi kata sandi Anda dengan siapa pun."
            >
              <b-form-input
                v-model="form.password"
                type="password"
                placeholder="Masukkan kata sandi Anda"
              ></b-form-input>
            </b-form-group>

            <b-button class="btn-purple" @click="handleMasuk">Masuk</b-button>
          </b-form>
        </div>
        <div class="right text-center align-content-center">
          <p class="quote">
            {{ `"${quotes.quote}"` }}
          </p>
          <p class="author">
            {{ quotes.author }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import api from '@/api'
import Loader from '@/components/Loader.vue'
import Navbar from '@/components/Navbar.vue'
import { toast } from 'vue3-toastify'

export default {
  name: 'MasukPage',
  components: {
    Loader,
    Navbar,
  },
  data() {
    return {
      isLoading: true,
      quotesIntervalId: null,
      quotes: {},
      form: {
        type: 'guru',
        identity: '',
        password: '',
      },
      typeOptions: [
        { value: 'guru', text: 'Guru' },
        { value: 'siswa', text: 'Siswa' },
      ],
    }
  },
  mounted() {
    const token = localStorage.getItem('token')
    if (token) {
      this.getUser()
    }

    window.addEventListener('keypress', this.isPressEnter)

    this.fetchQuotes()

    this.quotesIntervalId = setInterval(() => {
      this.fetchQuotes()
    }, 10000)
  },
  beforeUnmount() {
    clearInterval(this.quotesIntervalId)
  },
  methods: {
    async getUser() {
      await api
        .get('user')
        .then((response) => {
          if (response.data.data.nik) {
            this.$router.push({ name: 'GuruDashboard' })
          }

          if (response.data.data.nisn) {
            this.$router.push({ name: 'SiswaDashboard' })
          }
        })
        .catch((error) => {
          const tokenInvalid = error.response.status === 401

          if (tokenInvalid) {
            toast.error(error.response.data.message)
            localStorage.clear()
          }
        })
    },
    isPressEnter(event) {
      if (event.code === 'Enter') {
        this.handleMasuk()
      }
    },
    async fetchQuotes() {
      await axios
        .get('https://quotes-api-self.vercel.app/quote')
        .then((response) => {
          this.quotes = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async handleMasuk() {
      const type = this.form.type
      const identity = this.form.identity
      const password = this.form.password

      if (!identity || !password) {
        toast.error('Mohon isi semua kolom')
        return
      }

      if (type === 'guru' && identity.length < 16) {
        toast.error('NIK harus 16 digit')
        return
      }

      if (type === 'siswa' && identity.length < 10) {
        toast.error('NISN harus 10 digit')
        return
      }

      this.isLoading = true
      
      await api
        .post('login', {
          type: type,
          identity: identity,
          password: password,
        })
        .then((response) => {
          const token = response.data.token
          localStorage.setItem('token', token)

          toast.success(response.data.message)
          window.removeEventListener('keypress', this.isPressEnter)

          setTimeout(() => {
            if (type === 'guru') {
              this.$router.push({ name: 'GuruDashboard' })
            } else {
              this.$router.push({ name: 'SiswaDashboard' })
            }
          }, 2000)
        })
        .catch((error) => {
          toast.error(error.response?.data?.message || error.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.outer {
  background-image: radial-gradient(
      circle at 20% 60%,
      #8590c8 5%,
      transparent 30%
    ),
    linear-gradient(45deg, #41358e, #fef000);
  min-height: 100%;
  position: absolute;
  top: 0;
}

.masuk {
  margin: 10vw 5rem 3rem 5rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.left {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1rem 0 0 1rem;
  padding: 5vw;
  width: 60%;
}

.left img {
  width: 3rem;
  margin-bottom: 1rem;
}

.right {
  background: rgba(65, 53, 142, 0.7);
  border-radius: 0 1rem 1rem 0;
  padding: 5vw;
  width: 40%;
  color: #fff;
}

.right .author {
  font-weight: bold;
}

/* fhd */
@media (min-width: 1440px) {
  .masuk {
    margin: 10vw 20rem 3rem 20rem;
  }
}

@media (max-width: 991.98px) {
  .masuk {
    margin: 25vw 1rem 1rem 1rem;
    flex-direction: column;
  }

  .left {
    border-radius: 1rem 1rem 0 0;
    width: 100%;
  }

  .left .btn {
    width: 100%;
  }

  .right {
    border-radius: 0 0 1rem 1rem;
    width: 100%;
  }

  .right .author {
    margin-bottom: unset;
  }
}
</style>
