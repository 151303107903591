<template>
  <div>
    <b-navbar ref="navbar" toggleable="lg" type="light">
      <img @click="handleLink('Beranda')" src="@/assets/logo.png" alt="" draggable="false"/>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ms-auto align-items-center">
          <b-nav-item v-if="$route.name !== 'Beranda'" @click="handleLink('Beranda')">
            Beranda
          </b-nav-item>
          <b-nav-item @click="handleLink('Materi')">
            Materi
          </b-nav-item>
          <b-button
            v-if="$route.name !== 'Masuk'"
            @click="handleLink('Masuk')"
            class="btn-purple ms-lg-3 mt-4 mt-lg-0"
          >
            Masuk
          </b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'NavbarComponent',
  props: {
    links: {
      type: Array,
    },
  },
  methods: {
    handleLink(source) {
      this.closeNavbar()
      return this.$router.push({ name: source })
    },
    closeNavbar() {
      if (window.innerWidth > 992) {
        return
      }

      document.querySelector('.navbar-toggler').click()
    },
  },
}
</script>

<style scoped>
nav {
  padding-left: 5rem;
  padding-right: 5rem;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.06);
  background: #fff;
  z-index: 1;
}

nav img {
  max-width: 3rem;
  margin-right: 5rem;
}

nav img:hover {
  cursor: pointer;
}

nav li {
  margin-right: 2rem;
}

nav li:hover a {
  color: #41358e !important;
}

/* fhd */
@media (min-width: 1440px) {
  nav {
    padding-left: 20rem;
    padding-right: 20rem;
  }
}

/* mobile */
@media (max-width: 991.98px) {
  nav {
    padding-left: unset;
    padding-right: unset;
    margin-right: unset;
  }

  nav img {
    max-width: 2.5rem;
  }

  nav li {
    margin-right: unset;
    margin-bottom: 1rem;
  }

  nav li:last-child {
    margin-bottom: unset;
  }
}
</style>
