<template>
  <div>
    <div :class="{ 'sidebar bg-weak': true, open: isSidebarOpen }">
      <ul>
        <li
          v-for="(menu, index) in sidebarDataShow"
          :key="index"
          @click="handleMenu(menu.route)"
          @mouseover="handleMouse(true, menu.value)"
          :class="{
            active: routePath.includes(menu.route),
          }"
        >
          <div class="item">
            <i :class="`${menu.icon} icon fc-light fc-purple`"></i>
            <span class="fc-light fc-purple">{{ menu.name }}</span>
          </div>
          <div v-if="!routePath.includes(menu.route)" class="triangle"></div>
        </li>
      </ul>
    </div>
    <div
      :class="{ 'sidebar-secondary bg-weak': true, open: isHovered }"
      @mouseover="handleMouse(true)"
    >
      <li
        v-for="(item, itemName) in sidebarSecondaryData[sidebarSecondaryValue]"
        :key="itemName"
        @click="handleRouteSidebarSecondary(item.path)"
        :class="{ active: routePath.includes(item.path) }"
      >
        <div class="item">
          <i :class="`${item.icon} icon fc-light fc-purple`"></i>
          <span class="fc-light fc-purple">{{ item.name }}</span>
        </div>
      </li>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  data() {
    return {
      routePath: null,
      isHovered: false,
      sidebarDataGuru: [
        {
          name: 'Siswa',
          route: 'guru/siswa',
          icon: 'bi-people-fill',
          value: 'siswa',
        },
        {
          name: 'Soal',
          route: 'guru/dataset',
          icon: 'bi-file-earmark-text-fill',
          value: 'soal',
        },
        {
          name: 'Nilai',
          route: 'guru/nilai',
          icon: 'bi-graph-up',
          value: 'nilai',
        },
      ],
      sidebarDataSiswa: [
        {
          name: 'Paket Soal',
          route: 'siswa/paket',
          icon: 'bi-file-earmark-text-fill',
          value: 'paket',
        },
      ],
      sidebarSecondaryValue: null,
      sidebarSecondaryData: {
        siswa: {
          siswa: {
            name: 'Siswa',
            path: 'siswa',
            icon: 'bi-people-fill',
          },
        },
        soal: {
          dataset: {
            name: 'Dataset',
            path: 'dataset',
            icon: 'bi-list-stars',
          },
          paket: {
            name: 'Paket',
            path: 'paket',
            icon: 'bi-card-text',
          },
          soal: {
            name: 'Soal',
            path: 'soal',
            icon: 'bi-justify-left',
          },
        },
        nilai: {
          nilai: {
            name: 'Nilai',
            path: 'nilai',
            icon: 'bi-graph-up',
          },
        },
        paket: {
          paket: {
            name: 'Paket Soal',
            path: 'paket',
            icon: 'bi-justify-left',
          },
        },
      },
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  created() {
    this.routePath = this.$route.path
  },
  watch: {
    '$route.path'() {
      this.routePath = this.$route.path
    },
  },
  computed: {
    ...mapGetters(['getSidebarStatus', 'getUserData']),
    isSidebarOpen() {
      return this.getSidebarStatus
    },
    user() {
      return this.getUserData
    },
    sidebarDataShow() {
      const isGuru = this.user.nik !== undefined
      return isGuru ? this.sidebarDataGuru : this.sidebarDataSiswa
    },
  },
  methods: {
    handleMenu(type) {
      const route = `/${type}`

      if (!this.routePath.includes(type)) {
        this.$router.push(route)
      }
    },
    handleMouse(value, type) {
      this.isHovered = value

      if (type !== undefined) {
        this.sidebarSecondaryValue = type
      }
    },
    handleClickOutside(event) {
      if (!this.$refs.componentRef?.contains(event.target)) {
        this.isHovered = false
      }
    },
    handleRouteSidebarSecondary(route) {
      const routeSplit = this.routePath.split('/')
      const goToRoute = `/${routeSplit[1]}/${route}`
      this.$router.push(goToRoute)
      this.isHovered = false
    },
  },
}
</script>

<style scoped>
.sidebar {
  transition: left 0.3s ease-in-out;
  background: #fff;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  height: calc(100vh - 80px);
  width: 120px;
  left: -120px;
  position: fixed;
  z-index: 2;
  margin-top: 80px;
  overflow: overlay;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  display: block;
}

.sidebar.open {
  left: 0px;
}

.sidebar ul {
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.sidebar ul li {
  border-bottom: 1px solid #d1d5db;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
}

.dark .sidebar ul li {
  border-bottom: 1px solid #202020 !important;
}

.sidebar ul li .item {
  display: block;
  padding: 26px 0;
  width: 100%;
  color: #111827;
  text-decoration: none;
}

.sidebar ul li:hover .item, .sidebar ul li.active .item span, .sidebar ul li.active .item i {
  color: #41358e;
}

.sidebar .icon {
  font-size: 32px;
  display: block;
  margin: 0 auto 6px;
  width: 100%;
}

.sidebar ul li .item span {
  display: block;
  font-size: 13px;
  font-weight: 400;
}

.sidebar .triangle {
  border-color: transparent transparent #41358e;
  border-style: solid;
  border-width: 0 0 30px 30px;
  bottom: 0;
  height: 0;
  position: absolute;
  right: 0;
  width: 0;
  display: none;
}

.sidebar ul li:hover .triangle {
  display: unset;
}

.sidebar-secondary {
  background: #fff;
  left: -240px;
  padding: 0.75rem 0;
  transition: left 0.3s ease-in-out;
  width: 240px;
  z-index: 1;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  height: calc(100vh - 80px);
  position: fixed;
  top: 80px;
}

.sidebar-secondary.open {
  left: 120px;
}

.sidebar-secondary ul {
  list-style: none;
  padding: 0;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
}

.sidebar-secondary li {
  display: block;
  transition: all 0.15s ease-in;
}

.sidebar-secondary li:hover {
  background: #e4e7eb;
}

.dark .sidebar-secondary li:hover {
  background: #202020;
}

.sidebar-secondary li .item {
  color: #05070b;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  padding: 12px 24px;
  text-transform: capitalize;
  align-items: center;
}

.sidebar-secondary li .item .icon {
  color: #4b5563;
  font-size: 18px;
  margin-right: 12px;
  vertical-align: middle;
}

.sidebar-secondary li .item span {
  font-weight: 400;
  vertical-align: middle;
  font-size: 13px;
  color: #05070b;
}

.sidebar-secondary li.active .item .icon,
.sidebar-secondary li.active .item span {
  color: #41358e !important;
}
</style>
