import axios from 'axios'

const api = axios.create({
  // baseURL: 'http://127.0.0.1:8000/',
  baseURL: 'https://api.rizkyamien.com/',
})

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')

    const excludedUrls = ['login']
    const method = () => {
      if (config.url.includes('login')) {
        return true
      }

      // return config.method === 'get'
    }

    const isExcluded = excludedUrls.some((url) => {
      return config.url.includes(url) && method()
    })

    if (!isExcluded && token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default api
