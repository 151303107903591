<template>
  <div>
    <Loader v-if="isLoading" />
    <div class="row">
      <div class="col-12 align-self-center">
        <h2 class="mb-0 fw-bold">Tambah Dataset</h2>
      </div>
    </div>

    <hr class="mb-5" />

    <div class="row">
      <div class="col-12 col-md-6">
        <b-form-group label="Nama">
          <b-form-input
            v-model="form.nama"
            type="text"
            placeholder="Masukkan nama dataset"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group label="Deskripsi">
          <b-form-input
            v-model="form.deskripsi"
            type="text"
            placeholder="Masukkan deskripsi"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 col-md-6">
        <b-form-group label="Sekolah">
          <b-form-input
            v-model="sekolah"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-12 mt-5 text-end">
        <b-button class="btn-purple me-2" @click="handleBack">
          Kembali
        </b-button>
        <b-button class="btn-blue" @click="handleTambah">
          <i class="bi-plus-circle"></i>
          Tambah
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import { mapGetters } from 'vuex'
import api from '@/api'
import { toast } from 'vue3-toastify'

export default {
  name: 'TambahDatasetPage',
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: false,
      form: {
        nama: '',
        deskripsi: '',
      },
      sekolah: '',
    }
  },
  computed: {
    ...mapGetters(['getUserData']),
    user() {
      return this.getUserData
    },
  },
  watch: {
    user() {
      this.sekolah = this.user.sekolah.nama
    },
  },
  methods: {
    async handleTambah() {
      if (
        !this.form.nama ||
        !this.form.deskripsi
      ) {
        toast.error('Semua kolom harus diisi')
        return
      }

      this.isLoading = true

      await api
        .post('dataset', this.form)
        .then((response) => {
          toast.success(response.data.message)
          setTimeout(() => {
            this.$router.push({ name: 'Dataset' })
          }, 2000)
        })
        .catch((error) => {
          toast.error(error.response.data.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleBack() {
      this.$router.push({ name: 'Dataset' })
    },
  },
}
</script>

<style scoped></style>
